import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
// import Logo from './Logo.vue'
import SidebarItem from './SidebarItem.vue';

export default {
  components: { SidebarItem: SidebarItem },
  computed: _objectSpread(_objectSpread({},
  mapGetters(['sidebar', 'permission_routes'])), {}, {
    // routes() {
    //   return this.$router.options.routes
    // },
    activeMenu: function activeMenu() {
      var route = this.$route;var
      meta = route.meta,path = route.path;
      // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    } }) };